<template>
  <div class="viewContainer csv">
    <h1>Bestand updaten</h1>
    <b-row>
      <b-col>
        <label class="text-reader">
          <input type="file" @change="readCSV" />
        </label>
      </b-col>
    </b-row>
    <b-row v-if="csvColumns.length > 0">
      <b-col>
        <b-form-group label="Welche Spalten sollen zur Wein-Identifikation verwendet werden?" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="selectedIdentifier"
            :aria-describedby="ariaDescribedby"
            name="flavour-2"
          >
            <b-form-checkbox v-for="column in csvColumns" :value="column" :key="column" :disabled="isImporting">{{ column }}</b-form-checkbox>
          
          </b-form-checkbox-group>
        </b-form-group>
        <b-row>
          <b-col>
          Ausgewählte Spalten: {{ selectedIdentifier.join(', ') }}
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-form-group label="Welche Spalten sollen durch den Import welchen Wert verändern?" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="selectedFieldsToChange"
            :aria-describedby="ariaDescribedby"
            name="flavour-2"
          >
            <b-form-checkbox v-for="column in csvColumns" :value="column" :key="column" :disabled="isImporting || deleteWine" style="display: flex; align-items: center;">
              <b-row>
                <b-col style="display: flex; align-items: center; margin-left: 3px;width: 100px;">
                  {{ column }}
                </b-col>
                <b-col style="width: 120px;">
                  <b-form-select v-model="mappingObject[column]" :options="venueWineOptions" @change="clearMappingObject(column)"></b-form-select>
                </b-col>
              </b-row>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-checkbox v-model="deleteWine" :disabled="isImporting" @change="selectedFieldsToChange=[]" class="mt-3 mb-3">
          Wein löschen
        </b-form-checkbox>
        Datum des Imports: {{ importDate }}
        <b-form-datepicker :locale="$i18n.locale" id="fromDatePicker" v-model="importDate"></b-form-datepicker>
       <b-row>
          <b-col>
            Spalten, die importiert werden sollen: <br/> {{ selectedFieldsToChange.map((field) => { return field + ' -> ' + mappingObject[field] }).join(', ') }} 
          </b-col>
        </b-row>
        <b-row v-if="wineSupplierNotFound.length > 0 && selectedFieldsToChange.indexOf('Bezugsquelle') > -1">
          <b-col>
            <h2>
            Nicht gefundene Lieferanten (müssen vorher hinzugefügt werden):
            </h2>
            {{ wineSupplierNotFound.join(', ') }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    
    <b-row class="mt-3">
      <b-col>
        <b-button @click="updateVenueWines()">Update starten</b-button>
      </b-col>
      <b-col>
        Aktiueller Fortschritt: {{ currentRow }} / {{ csvInput.length }}
      </b-col>
    </b-row>

    <b-row v-if="notFoundWines.length > 0" class="mt-5">
      <b-col>
        <h2>
        Nicht gefundene Weine:
        </h2>
      </b-col>
      <b-col>
        Als CSV exportieren:
        <b-button @click="exportCSV()">Exportieren</b-button>
      </b-col>
    </b-row>
    <b-row v-if="notFoundWines.length > 0" class="mt-2">
      <b-col>
        <b-table striped hover :items="notFoundWines" :fields="notFoundColumns" >
        </b-table>
      </b-col>
    </b-row>
    
  </div>
</template>
<script>
import DataService from "@/services/StrapiService.js";
import { equalsIgnoreCase, parsePotentiallyGroupedFloat } from "@/utils/functions.js";
import { addSupplierAndPrice } from "@/utils/venueWineFunctions.js";
export default {
  name: "UpdateAmounts",
  data() {
    return {
      deleteWine: false,
      csvInput: [],
      csvError: [],
      csvColumns: [],
      selectedIdentifier: [
        'Weingut',
        'Weinname',
        'Jahrgang',
        'Flaschengröße',
      ],
      selectedFieldsToChange: [],
      selected: null,
      venueWineOptions: [
        { value: "amount", text: "amount" },
        { value: "sellingPrice", text: "sellingPrice" },
        { value: "shelfNumber", text: "shelfNumber" },
        { value: "articleNumber", text: "articleNumber" },
        { value: "comments", text: "comments" },
        { value: "cashNote", text: "cashNote" },
        { value: "purchasePrices", text: "supplier" },
      ],
      mappingObject: {
        'Anzahl': 'amount',
        'VK': 'sellingPrice',
        'Regal': 'shelfNumber',
        'Artikelnummer': 'articleNumber',
        'Kommentar': 'comments',
        'Kassennotiz': 'cashNote',
        'Bezugsquelle': 'purchasePrices',
      },
      identifierMappingObject: {
        'Artikelnummer': 'attributes.articleNumber',
        'Weingut': 'attributes.wine.data.attributes.winery.data.attributes.title',
        'Weinname': 'attributes.wine.data.attributes.title',
        'Jahrgang': 'attributes.year',
        'Region': 'attributes.wine.data.attributes.region.data.attributes.title',
        'Land': 'attributes.wine.data.attributes.land.data.attributes.title',
        'Farbe': 'attributes.wine.data.attributes.wineType.data.attributes.title',
        'Rebsorte': 'attributes.wine.data.attributes.grapeVariety.data.attributes.title',
        'Flaschengröße': 'attributes.bottleSize.data.attributes.amount',
      },
      isImporting: false,
      notFoundWines: [],
      currentRow: 0,
      notFoundColumns: [
        'Weingut',
        'Weinname',
        'Jahrgang',
        'Flaschengröße',
        'Grund'
      ],
      offlineWineSupplier: [],
      offlineWineSupplierList: [],
      wineSupplierNotFound: [],
      importDate: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() )),
      dateDidChange: false
    }
  },
  created() {
    this.getOfflineWineSupplier();
  },
  methods: {
    readCSV(event) {
      const file = event.target.files[0];
      const self = this;
      var csvinput = [];
      var csverror = [];
      var count = 0;
      // this.offlineWineSupplier.map((field) => {if (this.wineSupplierNotFound.indexOf(field.attributes.title) == -1) { this.wineSupplierNotFound.push(field.attributes.title)}}) 
      this.$papa.parse(file, {
        //worker: true, // Don't bog down the main thread if its a big file
        header: true,
        download: true,
        skipEmptyLines: true,
        transformHeader: function (h) {
          return h.trim();
        },
        step: function (result) {
          // do stuff with result
          count = count + 1;
          if (result.errors.length == 0) {
            // clean up data
            if (result.data['Bezugsquelle'] != null && self.offlineWineSupplierList.indexOf(result.data['Bezugsquelle']) == -1 && self.wineSupplierNotFound.indexOf(result.data['Bezugsquelle']) == -1)  {
              self.wineSupplierNotFound.push(result.data['Bezugsquelle']);
            }
          
            if (result.data['Flaschengröße'] != null && Number(result.data['Flaschengröße']) === 375 || result.data['Flaschengröße'] === '375') {
              result.data['Flaschengröße'] = 0.375;
            }
            if (result.data['EK'] != null) {
              result.data['EK'] = parsePotentiallyGroupedFloat(result.data['EK']);
            }
            if (result.data['VK'] != null) {
              result.data['VK'] = parsePotentiallyGroupedFloat(result.data['VK']);
            }
            csvinput.push(result.data);
          } else {
            csverror.push(result.errors[0]);
          }
        },
        complete: function () {
          self.csvError = csverror;
          self.csvInput = csvinput;
          self.useKeysFromObject(self.csvInput[0]);
        },
      });
    },
    useKeysFromObject(object) {
      this.csvColumns = Object.keys(object);
      var newSelectedIdentifier = [];
      for (var i = 0; i < this.selectedIdentifier.length; i++) {
        if (this.csvColumns.indexOf(this.selectedIdentifier[i]) > -1) {
          newSelectedIdentifier.push(this.selectedIdentifier[i]);
        }
      }
      this.selectedIdentifier = newSelectedIdentifier;
    },
 
    constructVenueWineObjectFromSelectedFields(csvRow, storedVenueWine) {
      var venueWineObject = {
        attributes: 
          {
            noRevenue: true,
            causeOfReduction: 'inventory'
          }
      };
      if (this.dateDidChange) {
        venueWineObject.attributes.newCreateDate = this.importDate;
      }
      if (this.deleteWine) {
        venueWineObject.attributes.deleted = true;
        return venueWineObject;
      }
      this.selectedFieldsToChange.forEach((field) => {
        var venueWineAtrribute = this.mappingObject[field];
        if (venueWineAtrribute == 'purchasePrices') {

          const wineSupplier = this.offlineWineSupplier.find((wineSupplier) => {
            return wineSupplier.attributes.title.toLowerCase() == csvRow[field].toLowerCase();
          });
          if (!wineSupplier) {
            console.log('No wine supplier found for ' + csvRow[field]);
            csvRow['Grund'] = 'Keinen vorhandenden Lieferant gefunden';
            this.notFoundWines.push(csvRow)
            return;
          }
          var venueWine = addSupplierAndPrice(storedVenueWine, csvRow['EK'], wineSupplier)
          venueWineObject['attributes'][venueWineAtrribute] = venueWine['attributes'][venueWineAtrribute]
        } else {
          venueWineObject['attributes'][venueWineAtrribute] = csvRow[field];
        }
        
      });
      return venueWineObject;
    },
    clearMappingObject(column) {
      var keysToDelete = []
      var copyOfMappingObject = JSON.parse(JSON.stringify(this.mappingObject));
      for (var key in this.mappingObject) {
        if (key !== column && this.mappingObject[key] == this.mappingObject[column]) {
          keysToDelete.push(key)
        }
      }
      keysToDelete.forEach((key) => {
        delete copyOfMappingObject[key];
      })
      this.mappingObject = copyOfMappingObject;
    },
    async getOfflineWineSupplier() {
      const venueId = this.$store.getters.getVenue.id;
      const wineSupplierResponse = await DataService.getAllWineSuppliers(
          venueId
        );
    this.offlineWineSupplier = wineSupplierResponse["data"].data;
    this.offlineWineSupplierList = this.offlineWineSupplier.map((wineSupplier) => {
      return wineSupplier.attributes.title;
    });
    },
    deepValueOfObject(object, path) {
      var pathArray = path.split('.');
      var value = object;
      pathArray.forEach((path) => {
        value = value[path];
      });
      return value;
    },
    async updateVenueWines() {
      this.isImporting = true
      const venueWineResponse = await DataService.getVenueWines();
      this.offlineVenueWine = venueWineResponse["data"].data;


      for await (const csvWine of this.csvInput) {
        this.currentRow = this.currentRow + 1;
        var matchingIdentifier= JSON.parse(JSON.stringify( this.selectedIdentifier));
        var venueWine = this.offlineVenueWine.find((offlineVenueWine) => {
          var isMatching = true;
          for (var i = 0; i <  this.selectedIdentifier.length; i++) {
            var identifier = this.selectedIdentifier[i];
            var venueWineIdentifier = this.identifierMappingObject[identifier];
            var valueA = this.deepValueOfObject(offlineVenueWine, venueWineIdentifier);
            var valueB = csvWine[identifier];
            var stringA = valueA;
            var stringB = valueB;
            
            if (Number.isInteger(valueA) && Number.isInteger(valueB)) {
              
              valueA = parseInt(valueA)
              valueB = parseInt(stringB)
              console.log('comparing ' + valueA + ' with ' + valueB);
              isMatching = valueA === valueB;
            } else if (valueA == 0 && valueB == 0) {
              isMatching = true
              console.log('comparing Zeros ' + valueA + ' with ' + valueB);
            } else if (stringA && stringB) {
              stringA = stringA.toString().toLowerCase().replace(",", ".").trim();
              stringB = stringB.toString().toLowerCase().replace(",", ".").trim();
              isMatching = equalsIgnoreCase(stringA,stringB);
            } else {
              isMatching = false
            }
            if (!isMatching){
              console.log('-- NO match for ' + identifier + ' ' + stringA + ' ' + stringB);
              return false
            } else {
              this.removeIdentifierFromArray(identifier, matchingIdentifier);
              console.log('** match ' + identifier + ' ' + csvWine[identifier] + ' ' + this.deepValueOfObject(offlineVenueWine, venueWineIdentifier));
            }
          }
          return isMatching
        });
       
        if (venueWine) {
          var venueWineObject = this.constructVenueWineObjectFromSelectedFields(csvWine, venueWine);
          var result = await DataService.updateVenueWine(venueWine.id, venueWineObject); 
          console.log(result);
        } else {
          csvWine['Grund'] = matchingIdentifier ? matchingIdentifier[0] : 'Kein Grund gefunden';
          this.notFoundWines.push(csvWine)
        }
      }
      this.isImporting = false
    },
    removeIdentifierFromArray(word, array) {
      var index = array.indexOf(word);
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    exportCSV() {
      var csv = this.$papa.unparse(this.notFoundWines);
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "notFoundWines.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  watch: {
    importDate: function () {
      this.dateDidChange = true;
    },
  },
};
</script>
```